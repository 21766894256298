import { ApiAxios } from './api.config';

const RESOURCE = '/admins';

export default {
  async login(user_email, user_password) {
    return await ApiAxios().post(`${RESOURCE}/login`, {
      user_email,
      password: user_password
    });
  },
  async checkIsAdmin() {
    return await ApiAxios().get(`${RESOURCE}/me`);
  }
};
